import styled from "styled-components";
import {useLocalStorage} from "react-use";

const InfoPageStyled = styled.div``;

const InfoPage = () => {
  const [userName, setUserName] = useLocalStorage('username', '');
  const [userId, setUserId] = useLocalStorage('userId', 0);

  return (
    <InfoPageStyled>
      <h1>Herzlich Willkommen<br/>
        {userName}!
      </h1>
      <p>
        Wir begrüßen Sie sehr herzlich zum diesjährigen Infotag. Hier haben wir noch einmal den Ablauf für heute
        zusammengestellt und beschrieben, bei welchen Punkten wir Ihre Hilfe brauchen.
      </p>
      <h3>10:00 / Podiumsgespräch (Eduard-Söring-Saal)</h3>
      <h3>10:30 / Messe (Kleine Turnhalle)</h3>
      <p>Etwa zur Halbzeit der Messe fangen wir mit der Registrierung für die Workshops an. Die Schüler kommen zu Ihnen,
        bitte scannen Sie mit der WebApp die Schüler-QR-Codes und fragen Sie die Schüler jeweils, ob sie in den Workshop
        1 oder in den Workshop 2 wollen. Die Listen sind begrenzt! Es werden Schüler zur Unterstützung bereitstehen.</p>
      <h3>11:15 / Workshops (Klassenräume)</h3>
      <p>Dieses Jahr gibt es wieder zwei Workshops. Bitte bestätigen Sie zum Start des Workshops die Anwesenheit der
        Schüler in der WebApp, dies dient der Anwesenheitskontrolle. Bitte achten Sie darauf, dass die Schüler beim
        Klingeln direkt den Raum wechseln, es ist nicht viel Zeit. </p>
      <h3>12:15 / Ausklang (Cafeteria)</h3>
      <p>In der Cafeteria erwarten wir Sie alle zum Ausklang des Infotages.</p>
      <p><strong>Bitte geben Sie Ihr Namensschild beim Orgateam ab. Das Orgateam wird dann auch überprüfen, ob Ihre
        Anwesenheitslisten synchronisiert wurden.</strong></p>

    </InfoPageStyled>
  )
}

export default InfoPage;